<template>
    <div class="internal-sections">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">
                            Litsenziatlar qo'shish
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/licenseTable' }">
                    Litsenziatlar
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    Litsenziatlar qo'shish
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="doctor-add card mt-30">
            <div class="card-body">
                <el-steps :active="3" align-center finish-status="success">
                    <el-step title="Barchasi"> </el-step>
                    <el-step title="Arizalar"> </el-step>
                    <el-step title="Ko'rilmoqda"> </el-step>
                    <el-step title="Ruxsat"> </el-step>
                    <el-step title=" Rad etish"> </el-step>
                </el-steps>
                <div class="steps-body">
                    <el-form ref="form" :model="form" class="my-form">
                        <div class="license-clinic-infi-itme">
                            <div class="card item-doctor">
                                <el-row :gutter="20">
                                    <el-col :span="14">
                                        <div class="infi-row d-flex">
                                            <div class="item-doctor-img">
                                                <img
                                                    class="img-avatar radus-5"
                                                    src="./../../../public/img/klinika.jpg"
                                                />
                                            </div>
                                            <div class="rif flex-1">
                                                <span class="job">
                                                    Azimov Abdulahad
                                                </span>
                                                <h4 class="title">GOMED MAX</h4>
                                                <p>
                                                    <i class="el-icon-discount">
                                                    </i>
                                                    Cтационар
                                                </p>
                                                <p>
                                                    INN: 999 888 777
                                                    <i class="icon-dot"> ● </i>
                                                    MFO: 47 879 5467
                                                    <i class="icon-dot"> ● </i>
                                                    R/S BANK: 112 7894 56
                                                </p>
                                                <p>
                                                    <i
                                                        class="
                                                            el-icon-location-outline
                                                        "
                                                    >
                                                    </i>
                                                    Viloyatlar: Farg'ona
                                                    <i
                                                        class="
                                                            el-icon-d-arrow-right
                                                        "
                                                    ></i>
                                                    Shahar: Farg'ona
                                                    <i
                                                        class="
                                                            el-icon-d-arrow-right
                                                        "
                                                    ></i>
                                                    Tuman:
                                                </p>

                                                <p>
                                                    <i
                                                        class="
                                                            el-icon-location-outline
                                                        "
                                                    >
                                                    </i>
                                                    г.Ташкент, Юнусабадский
                                                    район, массив МИНОР (Ц-6), 6
                                                </p>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item label="Litsenziya turi">
                                            <el-select
                                                v-model="form.regssion"
                                                placeholder="Litsenziya turi"
                                                :disabled="true"
                                            >
                                                <el-option
                                                    label="Umumiy"
                                                    value="shanghai"
                                                ></el-option>
                                                <el-option
                                                    label="Tez"
                                                    value="shanghassi"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="Sharh Qoldirish">
                                            <el-input
                                                type="textarea"
                                                v-model="form.desc"
                                                :disabled="true"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>

                        <div class="license-clinic-infi-itme childirin">
                            <div class="card item-doctor">
                                <el-row :gutter="20">
                                    <el-col :span="14">
                                        <div class="infi-row d-flex">
                                            <div class="item-doctor-img">
                                                <img
                                                    class="img-avatar radus-5"
                                                    src="./../../../public/img/klinika.jpg"
                                                />
                                            </div>
                                            <div class="rif flex-1">
                                                <span class="job">
                                                    Azimov Abdulahad
                                                </span>
                                                <h4 class="title">GOMED MAX</h4>
                                                <p>
                                                    <i class="el-icon-discount">
                                                    </i>
                                                    Cтационар
                                                </p>
                                                <p>
                                                    INN: 999 888 777
                                                    <i class="icon-dot"> ● </i>
                                                    MFO: 47 879 5467
                                                    <i class="icon-dot"> ● </i>
                                                    R/S BANK: 112 7894 56
                                                </p>
                                                <p>
                                                    <i
                                                        class="
                                                            el-icon-location-outline
                                                        "
                                                    >
                                                    </i>
                                                    Viloyatlar: Farg'ona
                                                    <i
                                                        class="
                                                            el-icon-d-arrow-right
                                                        "
                                                    ></i>
                                                    Shahar: Farg'ona
                                                    <i
                                                        class="
                                                            el-icon-d-arrow-right
                                                        "
                                                    ></i>
                                                    Tuman:
                                                </p>

                                                <p>
                                                    <i
                                                        class="
                                                            el-icon-location-outline
                                                        "
                                                    >
                                                    </i>
                                                    г.Ташкент, Юнусабадский
                                                    район, массив МИНОР (Ц-6), 6
                                                </p>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="10" class="der-q">
                                        <el-form-item
                                            label="Litsenziya turi"
                                            class="w-50"
                                        >
                                            <el-select
                                                v-model="form.regssion"
                                                placeholder="Litsenziya turi"
                                                :disabled="true"
                                            >
                                                <el-option
                                                    label="Umumiy"
                                                    value="shanghai"
                                                ></el-option>
                                                <el-option
                                                    label="Tez"
                                                    value="shanghassi"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item
                                            label="Sharh Qoldirish"
                                            class="w-50"
                                        >
                                            <el-input
                                                type="textarea"
                                                v-model="form.desc"
                                                :disabled="true"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentStep: 1,
            loading: false,
            form: {
                type: [],
            },
            cities: [
                {
                    value: "000 008 101",
                    label: "MED Ali",
                },
                {
                    value: "000 008 102",
                    label: "Klinik MAX",
                },
            ],

            value: "",
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            fileList: [
                {
                    name: "food.jpeg",
                    url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
                },
            ],
        };
    },
};
</script>
<style>
</style>
